<template>
  <div id="container">
    <header-navbar class="sticky" bgTheme="bg-deep-blue-2"></header-navbar>

    <img class="bg-1" :src="pageImage['bg-1']" alt="" />

    <!-- 底部 -->
    <floor-bg class="floor-bg" bgTheme="floor-bg-white"></floor-bg>
  </div>
</template>

<script setup>
import { computed, reactive } from 'vue'
const pageImage = computed(() => {
  const imgs = ['bg-1']

  const result = {}

  for (let i = 0; i < imgs.length; i++) {
    const item = imgs[i]
    result[
      item
    ] = require(`@/assets/images/page-body/yunchong-intelligence/${item}.png`)
  }

  return result
})
</script>

<style lang="scss" scoped>
#container {
  width: 100%;
  display: flex;
  align-content: center;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  background-color: #f4f8fd;

  .bg-1 {
    width: 1920px;
    height: auto;
  }

  .floor-bg {
    margin-top: 130px;
  }
}
</style>
